<template>
  <TransitionRoot :show="isOpen"
                  as="template"
                  enter="duration-300 ease-out"
                  enter-from="opacity-0 scale-95"
                  enter-to="opacity-100 scale-100"
                  leave="duration-200 ease-in"
                  leave-from="opacity-100 scale-100"
                  leave-to="opacity-0 scale-95"
  >
    <div class="cookie-notice fixed bottom-0 md:bottom-6 left-1/2 -translate-x-1/2 z-50 w-full max-w-[740px] p-6 bg-white rounded-3xl overflow-auto min-h-[152px] max-h-[60%] flex flex-wrap gap-4 shadow-even2xl"
         data-lenis-prevent
    >
      <h2 class="text-2xl w-full">
        About cookies
      </h2>
      <div class="flex flex-col md:flex-row items-end md:items-start gap-4 md:gap-6">
        <p class="w-auto text-gray-300 font-normal">
          This website uses cookies for the display of the website and its features. Please see more
          information about cookies used in our <NuxtLink href="/cookies/"
                                                          class="underline font-medium"
          >
            Cookie Policy
          </NuxtLink>.
        </p>
        <CookieButton :label="'Ok'"
                      @click="handleButtonClick()"
        />
      </div>
    </div>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { TransitionRoot } from '@headlessui/vue'

const emit = defineEmits(['give-consent'])

const isOpen = ref(false)

function openNotice() {
  isOpen.value = true
}

function closeNotice() {
  isOpen.value = false
}

function handleButtonClick() {
  closeNotice()
  emit('give-consent')
}

onMounted(() => {
  openNotice()
})
</script>
